import React from "react"
import Layout from "../components/layout"
import ProjectCard from "../components/projectCard"
import SEO from "../components/seo"
import { SectionHeader } from "../utils/commonComponents"
import { projects } from "../data/portfolio"

const Projects = () => (
  <Layout>
    <SEO title="Projects" />
    <SectionHeader header="PROJECTS" />
    {projects.map((project, idx) => (
      <div
        data-sal={idx % 2 === 0 ? "slide-right" : "slide-left"}
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
        key={project.title}
      >
        <ProjectCard data={project} key={project.title} />
      </div>
    ))}
  </Layout>
)

export default Projects
